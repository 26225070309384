import React, { useState, useEffect } from 'react'
import "./index.less"
import { useNavigate } from 'react-router-dom';
import { CookieExistsLogin } from '../../utils/login';
import { Button, Input, message } from 'antd';
import { editUserInfo, getUserInfo } from '../../api/modules/person';
import ImgUpload from '../../components/ImgUpload';
import { imageUpload } from '../../api/modules/product';
import { useDispatch } from 'react-redux';
import { changeUserInfo } from '../../store/modules/loginSlice';
import { queryUserToken } from '../../api/modules/github';

type userInfoType = {
  avatar: string,
  createTime: string,
  nickname: string,
  phone: string,
  userId: string
}

interface UserGithubInterface {
  unionId?: string         // 判断是否绑定github
  username?: string        // github用户名
  token?: string           // github访问令牌
  [key: string]: any
}

const PersonalCenter: React.FC = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  //头像更换是否显示
  const [changeLogo, setChangeLogo] = useState(false)

  // 用户信息
  const [userInfo, setUserInfo] = useState<userInfoType>({
    "avatar": "",
    "createTime": "",
    "nickname": "",
    "phone": "",
    "userId": ""
  })

  //昵称是否编辑中
  const [changeUserName, setChangeUserName] = useState(false)

  // 用户绑定github的信息
  const [userGithub, setUserGithub] = useState<UserGithubInterface>({})

  // 监听当前是否登录
  useEffect(() => {
    if (!CookieExistsLogin()) {
      navigate('/')
    }
  }, [])

  //获取个人信息
  const getInfo = () => {
    getUserInfo().then(res => {
      setUserInfo(res)
      dispatch(changeUserInfo({
        userInfo: {
          userphone: res?.nickname,
          userId: res?.userId,
          avatar: res?.avatar,
        }
      }))
    })
  }

  // 查询是否绑定 github
  const fetchGithub = async () => {
    const res = await queryUserToken('github')
    setUserGithub(res)
  }

  //获取上传图片
  const aboutMeUpload = () => {
    setChangeLogo(false)
    let load = document.getElementById("logoUpload")
    const antup = load?.getElementsByTagName("input")[0]
    antup?.click()
  }

  // 上传图片
  const getAboutImage = (e: any) => {
    setChangeLogo(false)
    const formData = new FormData();
    formData.append('file', e)
    imageUpload({ file: formData }).then((res) => {
      let info = JSON.parse(JSON.stringify(userInfo))
      info.avatar = res.url
      setUserInfo(info)
      saveInfo(info)
    })
  };

  //保存个人信息
  const saveInfo = (value?: any) => {
    editUserInfo(value ? value : userInfo).then(res => {
      dispatch(changeUserInfo({
        userInfo: {
          userphone: value ? value.nickname : userInfo.nickname,
          userId: value ? value.userId : userInfo.userId,
          avatar: value ? value.avatar : userInfo.avatar,
        }
      }))
    })
  }

  // 初始化数据
  useEffect(() => {
    getInfo()
    fetchGithub()
  }, [])

  return (
    <div className='personal'>
      <div className='personal-container'>
        <p className='personal-title'>基本信息</p>
        <div className='personal-container-one'>

          <div className='personal-personalInfo-container-logoimg' onMouseEnter={() => setChangeLogo(true)} onMouseLeave={() => setChangeLogo(false)} >
            <img src={userInfo?.avatar ? userInfo?.avatar : require('../../assets/images/signlogo.png')} alt="" className='img' />
            {changeLogo && <div className='personal-personalInfo-container-logoimg-hover' onClick={aboutMeUpload}>更换</div>}
            <div id="logoUpload" style={{ position: 'absolute', bottom: '10000px' }} >
              <ImgUpload proportion={16} shape={true} getImage={(e: any) => getAboutImage(e)}  ></ImgUpload>
            </div>
          </div>

          <div className='personal-container-one-right'>
            <div className='personal-container-one-right-item' style={{ marginBottom: '20px' }}>
              <p className='title'>昵称</p>
              <div className='context'>
                <Input style={{ width: 'calc(100% - 76px)' }} value={userInfo?.nickname} disabled={!changeUserName} onChange={(e) => {
                  let info = JSON.parse(JSON.stringify(userInfo))
                  info.nickname = e.target.value
                  setUserInfo(info)
                }} allowClear maxLength={10}></Input>
                {
                  changeUserName ? <>
                    <span className='btn' onClick={() => {
                      getInfo()
                      setChangeUserName(false)
                    }}>取消</span>
                    <span className='btn' onClick={() => {
                      if (userInfo.nickname.trim()) {
                        setChangeUserName(false)
                        saveInfo()
                      } else {
                        message.destroy()
                        message.warning('昵称不能为空!')
                      }
                    }}>保存</span>
                  </> : <span className='btn' onClick={() => {setChangeUserName(true)}}>修改</span>
                }
              </div>
            </div>

            <div className='personal-container-one-right-item' style={{ marginBottom: '20px' }}>
              <p className='title'>手机号</p>
              <Input style={{ width: 'calc(100% - 76px)' }} disabled value={userInfo?.phone}></Input>
            </div>

            <div className='personal-container-one-right-item'>
              <p className='title'>注册时间</p>
              <Input style={{ width: 'calc(100% - 76px)' }} disabled value={userInfo?.createTime}></Input>
            </div>
          </div>
        </div>
      </div>

      <div className='personal-container'>
        <p className='personal-title'>第三方管理</p>
        <div className='personal-container-two'>
          <div className='personal-container-two-item'>
            <img src={require('../../assets/images/github.png')} alt="" />
            <span className='personal-container-two-item-title'>GitHub管理</span>
            <Button type='primary' style={{width: '64px'}} onClick={() => {navigate(`/user/github`)}}>{userGithub?.token && userGithub?.unionId ? '查看' : '去设置'}</Button>
          </div>

          <div className='personal-container-two-line'></div>
          <div className='personal-container-two-item'>
            <img src={require('../../assets/images/gitee.png')} alt="" />
            <span className='personal-container-two-item-title'>Gitee管理</span>
            <Button type='primary' disabled style={{width: '64px'}}>查看</Button>
          </div>

          <div className='personal-container-two-line'></div>
          <div className='personal-container-two-item'>
            <img src={require('../../assets/images/wechart.png')} alt="" />
            <span className='personal-container-two-item-title'>微信绑定</span>
            <Button type='primary' disabled style={{width: '64px'}}>查看</Button>
          </div>

          <div className='personal-container-two-line'></div>
          <div className='personal-container-two-item'>
            <img src={require('../../assets/images/yuque.png')} alt="" />
            <span className='personal-container-two-item-title'>语雀</span>
            <Button type='primary' disabled style={{width: '64px'}}>查看</Button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default PersonalCenter;
