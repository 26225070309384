import { h } from 'snabbdom'
import { IDomEditor, IModuleConf } from '@wangeditor/editor'
import { v4 as uuidv4 } from 'uuid'
import withFillBlank from './plugin'
import InsertFillBlankMenu from './menu'

const renderFillBlank = (elem: any, children: any, editor: any) => {
  const elems = editor?.getElemsByType("fill-blank")
  const index: number = elems?.findIndex((child: any) => child["key"] === elem["key"]) || 0

  return h('input', {
    attrs: {
      class: 'fill-blank',
      'data-index': index + 1,
      'data-w-e-type': 'fill-blank',
      'data-w-e-is-void': 'true',
      'data-w-e-is-inline': 'true',
      readonly: true,
      placeholder: index + 1
    },
    style: {
      border: 'none',
      borderBottom: '1px solid #333',
      width: '72px',
      outline: 'none',
    }
  })
}

const fillBlankToHtml = (elem: any, childrenHtml: any, editor: IDomEditor | undefined) => {
  const elems = editor?.getElemsByType("fill-blank");
  const index: number = elems?.findIndex((child: any) => child["key"] === elem["key"]) || 0;
  return `<input data-index="${index + 1}" placeholder="${index + 1}" data-w-e-type="fill-blank" data-w-e-is-void="true" data-w-e-is-inline="true" readonly="true" style="border:none;border-bottom:1px solid #333;width:72px;outline:none;">`
}

const parseFillBlankHtml = (elem: any, children: any, editor: any) => {
  return {
    type: 'fill-blank',
    key: uuidv4(),
    children: [{text: ""}]
  }
}

const fillBlankModule: Partial<IModuleConf> = {
  editorPlugin: withFillBlank,
  renderElems: [
    {
      type: 'fill-blank',
      renderElem: renderFillBlank
    }
  ],
  elemsToHtml: [
    {
      type: 'fill-blank',
      elemToHtml: fillBlankToHtml
    }
  ],
  parseElemsHtml: [
    {
      selector: "input[data-w-e-type='fill-blank']",
      parseElemHtml: parseFillBlankHtml
    }
  ],
  menus: [
    {
      key: 'fill-blank',
      factory() {
        return new InsertFillBlankMenu()
      }
    }
  ]
}

export default fillBlankModule
