import React, { useRef, useState } from 'react'
import './index.less'
import { useCodeTimer } from '../../../hooks/CodeTimer'
import { Button, Form, Input, message } from 'antd'
import { verifyCode, verifyPhone } from '../../../utils/verify'
import HowGetCode from '../../../components/HowGetCode'
import PriPolicy from '../../../components/PriPolicy'
import OtherLogin from './OtherLogin'
import { getDetail, getSms } from '../../../api/modules/common'
import { register as getRegister } from '../../../api/modules/common'
import { useSearchParams } from 'react-router-dom'
import config from '../../../config'
import { getKaiYuanLoginSlient, getTopHost, getYunYanLoginSlient, handleHost } from '../../../utils/url'
import Cookies from 'js-cookie'
import { useLogin } from '../../../hooks/Login'

type RegisterFormProps = {

}

const RegisterForm: React.FC<RegisterFormProps> = (props) => {

  const { } = props

  const [form] = Form.useForm()

  const [search] = useSearchParams()
  const { login: setLogin } = useLogin()

  // 回调地址
  // const _redirect = search.get('redirect') ? decodeURIComponent(search.get('redirect')!) : config.kaiyuanURL!

  const [time, keepTime, handleTime] = useCodeTimer()

  const [loading, setLoading] = useState(false)

  // 注册
  const register = async () => {
    const values = await form.validateFields().catch(() => { })
    if (!values) return
    if (loading) return
    setLoading(true)
    const res = await getRegister({
      nickname: values.nickname.trim(),
      phone: values.phone.trim(),
      code: values.smsCode.trim(),
      inviteCode: values.invitation.trim()
    }).catch((error) => {
      const { code, message } = JSON.parse(error.message)
      switch (code) {
        case 1:
          form.setFields([{
            name: 'phone',
            errors: [message]
          }])
          break
        case 2:
          form.setFields([{
            name: 'smsCode',
            errors: [message]
          }])
          break
        case 3:
          form.setFields([{
            name: 'invitation',
            errors: [message]
          }])
          break
        default:
          break
      }
    }).finally(() => {
      setLoading(false)
    })
    if (!res) return
    await handleToken(res.token)
    window.open(getYunYanLoginSlient(`?redirect=${encodeURIComponent('')}&token=${res.token}`), '_self')
    // const redirect = new URL(_redirect)
    // // 重定向的域名  当前的域名   只截取后面的一级域名
    // const redirectHost = handleHost(redirect.host)
    // const localtHost = handleHost(window.location.host)
    // // 域名相同都是云燕，直接跳转重定向地址
    // if (redirectHost === localtHost) {
    //   window.open(getYunYanLoginSlient(`?redirect=${encodeURIComponent(_redirect)}&token=${res.token}`), '_self')
    // } else { // 域名不相同说明是其他域跳转过来的,跳转其他域的登录静默页，其他静默页为开源学堂
    //   window.open(getKaiYuanLoginSlient(`?redirect=${encodeURIComponent(_redirect)}&token=${res.token}`), '_self')
    // }
  }

  // 在当前域设置cookie
  const handleToken = async (_token: string) => {
    Cookies.set('token', _token, { domain: getTopHost() || undefined, samesite: 'None', secure: true })
    const res = await getDetail().catch(() => {})
    if (!res) return
    const { isLogin, token, sign, partner, userId } = res
    setLogin({token, sign, partner, userId, }, res)
  }

  const codeLoading = useRef(false)
  // 注册获取验证码
  const getCode = async () => {
    const values = await form.validateFields(['phone']).catch(() => { })
    if (!values) return
    if (codeLoading.current) return
    codeLoading.current = true
    const res = await getSms({ phone: values.phone.trim(), type: 2 }).catch(() => { }).finally(() => {
      codeLoading.current = false
    })
    if (!res) return
    message.success('验证码已发送')
    keepTime(handleTime(res.minutesLeft))
  }

  return <>
    <Form
      name="login-all-form"
      className='login-all-form'
      validateTrigger='onBlur'
      autoComplete="off"
      form={form}
    >
      <Form.Item
        label={false}
        name="nickname"
        rules={[
          { required: true, message: '请输入昵称', validateTrigger: 'onSubmit', whitespace: true },
        ]}
      >
        <Input
          maxLength={10}
          prefix={
            <div className='login-all-form-prefix' >
              <img src={require('../../../assets/images/name.png')} alt="" style={{ width: '20px', height: '20px' }} />
            </div>
          }
          placeholder="请输入昵称"
          allowClear />
      </Form.Item>

      <Form.Item
        label={false}
        name="phone"
        rules={[
          { required: true, message: '请输入手机号码', validateTrigger: 'onSubmit', whitespace: true },
          {
            validator: (_, value) => {
              if (!value) return
              if (verifyPhone(value)) {
                return Promise.resolve()
              }
              return Promise.reject('请填写有效手机号码')
            }, validateTrigger: 'onBlur'
          }
        ]}
        validateFirst
      >
        <Input
          maxLength={11}
          prefix={
            <div className='login-all-form-prefix' >
              <img src={require('../../../assets/images/phoneIcon.png')} alt="" />
            </div>
          }
          placeholder="请输入手机号码"
          allowClear />
      </Form.Item>

      <Form.Item
        label={false}
        name="smsCode"
        rules={[
          { required: true, message: '请输入验证码', validateTrigger: 'onSubmit', whitespace: true },
          {
            validator: (_, value) => {
              if (!value) return
              if (verifyCode(value)) {
                return Promise.resolve()
              }
              return Promise.reject('请输入有效验证码')
            }, validateTrigger: 'onBlur'
          }
        ]}
        validateFirst
      >
        <Input
          maxLength={6}
          placeholder="请输入验证码"
          prefix={
            <div className='login-all-form-prefix'>
              <img src={require('../../../assets/images/login-code.png')} alt="" />
            </div>
          }
          allowClear
          suffix={
            time === -1 ? <div className='login-all-form-suffix-getCode' onClick={(e) => { e.stopPropagation(); time === -1 && getCode?.() }}>获取验证码</div> :
              <div className='login-all-form-suffix-getCodeValue'>{time}s后重发</div>
          }
        />
      </Form.Item>

      <Form.Item
        label={false}
        name="invitation"
        rules={[
          { required: true, message: '请输入6位邀请码', validateTrigger: 'onSubmit', whitespace: true },
          {
            validator: (_: any, value: string) => {
              if (!value) {
                return Promise.resolve()
              }
              if (value.length < 6) {
                return Promise.reject('请输入6位邀请码')
              }
              return Promise.resolve()
            }, validateTrigger: 'onBlur'
          }
        ]}
        validateFirst
      >
        <Input
          maxLength={6}
          prefix={
            <div className='login-all-form-prefix' >
              <img src={require('../../../assets/images/invitationIcon.png')} alt="" />
            </div>
          }
          placeholder="请输入6位邀请码"
          allowClear />
      </Form.Item>

      <HowGetCode className='login-all-form-tip' />

    </Form>

    <PriPolicy style={{ paddingTop: '28px', paddingBottom: '16px' }} />

    <Button loading={loading} onClick={register} htmlType='button' type='primary' block style={{ marginBottom: '30px' }}>注 册</Button>

    <OtherLogin />

  </>
}

export default RegisterForm