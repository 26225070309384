import React from 'react'
import './index.less'
import { getPlatformAuth } from '../../../api/modules/login'
import config from '../../../config'
import { useSearchParams } from 'react-router-dom'

type OtherLoginProps = {

}

const OtherLogin: React.FC<OtherLoginProps> = (props) => {

    const { } = props

    const [search] = useSearchParams()

    // // 回调地址
    // const _redirect = search.get('redirect') ? decodeURIComponent(search.get('redirect')!) : config.kaiyuanURL!
    // const _redirect = window.location.href

    // 三方登录
    const gotoAuth = async (type: string) => {
        const res = await getPlatformAuth(type, {redirect: '', action: 'thirdlogin'}).catch(() => { })
        if (res?.url) {
            window.open(res.url, '_self')
        }
    }

    return (
        <>
            <div className='login-all-otherlogin'>
                <div className='login-all-otherlogin-item' onClick={() => (gotoAuth('github'))}>
                    <img src={require('../../../assets/images/github.png')} alt="" />
                    <span>Github</span>
                </div>
                <div className='login-all-otherlogin-item' onClick={() => (gotoAuth('gitee'))}>
                    <img src={require('../../../assets/images/gitee.png')} alt="" />
                    <span>Gitee</span>
                </div>
                <div className='login-all-otherlogin-item' onClick={() => (gotoAuth('wechat'))}>
                    <img src={require('../../../assets/images/wechart.png')} alt="" />
                    <span>微信</span>
                </div>
                <div className='login-all-otherlogin-item' onClick={() => (gotoAuth('yuque'))}>
                    <img src={require('../../../assets/images/yuque.png')} alt="" />
                    <span>语雀</span>
                </div>
            </div>
        </>
    )
}

export default OtherLogin