import { queryDefaultLab } from "../api/modules/blog"
import config from "../config"
import { navigateRoutes } from "../router/admin"
import { LabType } from "./globalConst"

// 获取浏览器地址
export const getUrl = (): string => window.location.href

// 获取域名
export const getHost = (): string => window.location.hostname

// 获取顶级域名
export const getTopHost = (): string | null | undefined => {
    const host = getHost()
    const temp = host?.split('.')
    if (temp?.length > 1) {
        return temp[temp.length - 2] + '.' + temp[temp.length - 1]
    }
}

// 获取项目学习首页
export const getStudy = (): string | null | undefined => {
    const url = window.location.origin
    const host = getHost()
    const second = getSecondHost() || ''
    if (host && second && url) {
        return url.replace(host, second)
    }
    return url || ''
}

// 获取二级域名
export const getSecondHost = (): string | null | undefined => {
    const host = getHost()
    const temp = host?.split('.')
    if (temp?.length > 2) {
        return temp[temp.length - 3] + '.' + temp[temp.length - 2] + '.' + temp[temp.length - 1]
    }
    return ''
}

// 根据三级域名获取实验室
export const getLabCodeFromHost = (): string | null | undefined => {
    const host = getHost()
    const temp = host?.split('.')
    if (temp?.length === 3) return
    if (temp?.length === 4) return temp[0]
}

/**
 *  根据实验室code生成前往实验室主页的地址
 * @param labCode  实验室code
 * @param splice   剩余拼接路径
 * 新增参数   实验室类型
 * @returns        url
 */
export const generateLabHomeUrl = (labCode: string, splice: string = '', labType?: string | number): string => {
    if (labType) {
        return getHostFromLabType(labType as any, labCode) + '/' + splice
    }
    return `${window.location.protocol}//${labCode}.${getSecondHost()}${window.location.port ? ':' + window.location.port : ''}/` + splice
}

// 根据实验室code,课程code,实验室类型生成课程详情页地址
export const generateCourseDetailUrl = (labCode: string, courseCode: string, labType?: string | number, splice?: string) => {
    return generateLabHomeUrl(labCode, `os/${courseCode}` + (splice ?? ''), labType)
}

// 生成家目录
export const generateHomeUrl = (splice: string = '', labType?: string | number): string => {
    if (labType) {
        return getHostFromLabType(labType as any) + '/' + splice
    }
    return `${window.location.protocol}//${getSecondHost()}${window.location.port ? ':' + window.location.port : ''}/` + splice
}










// export const recordHost = ['oscollege.net', 'cloudlab.top']


// 返回当前url  是否需要编码
export const getLocation = (encode: boolean = true) => {
    if (encode) {
        if (decodeURIComponent(window.location.href) === window.location.href) {
            return encodeURIComponent(window.location.href)
        } else {
            return encodeURIComponent(window.location.href)
        }
    }
    return window.location.href
}

// 云燕前缀地址 开头需要自己带 /
export const getYunYan = (url: string = '') => {
    return config.yunyanURL + url
}

// 开源前缀地址 开头需要自己带 /
export const getKaiYuan = (url: string = '') => {
    return config.kaiyuanURL + url
}

// 云燕登录静默页
export const getYunYanLoginSlient = (url: string = '') => {
    return config.yunyanURL + '/login/callback' + url
}

// 开源登录静默页
export const getKaiYuanLoginSlient = (url: string = '') => {
    return config.kaiyuanURL + '/login/callback' + url
}

// 处理域名
export const handleHost = (host: string) => {
    const temp = host.split('.')
    if (temp?.length > 1) {
        return temp[temp.length - 2] + '.' + temp[temp.length - 1]
    }
}

// 地址不存在 统一重定向到个人实验室
export const handleRedirect = async (redirect: any) => {
    try {
        const url = new URL(redirect)
        return redirect
    } catch (error) {
        const res = await queryDefaultLab()
        if (res?.code) {
            return generateHomeUrl(`${navigateRoutes?.home?.slice(1)}?labCode=${res?.code}`, res?.type)
        }
        return redirect
    }
}


// 根据实验室类型获取域名host
// 项目实验室0、个人实验室1、机构实验室2(暂时未使用)、教培实验室3、官方实验室10
export const getHostFromLabType = (_type: LabType | keyof typeof LabType, labCode?: string) => {
    const type: number = typeof _type === 'string' ? LabType[_type] : _type
    let hostUrl = ''
    switch (type) {
        case 0:
            hostUrl = config.kaiyuanURL!
            break;
        case 1:
        case 2:
        case 3:
        case 10:
            hostUrl = config.yunyanURL!
            break;
        default:
            hostUrl = config.yunyanURL!
            break;
    }
    const url = new URL(hostUrl)
    if (labCode) {
        hostUrl = hostUrl.replace(url.host, `${labCode}.${url.host}`)
    }
    return hostUrl
}

// 判断是否为个人实验室
export const isPERSONAL = (_type: LabType | keyof typeof LabType) => {
    const type: number = typeof _type === 'string' ? LabType[_type] : _type
    return type === 1
}

// 判断是不是一个有效的url地址
export const isURL = (str: string) => {
    try {
        const url = new URL(str)
        return true
    } catch (error) {
        return false
    }
}

