import React, { useState, useEffect, useRef } from 'react'
import "./index.less"
import * as url from '../../../../utils/url'
import RemarkItem from './components/RemarkItem';
import { Pagination, message } from 'antd';
import RichEditor from '../../../RichEditor';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { compentAnswers, getAnswerList } from '../../../../api/modules/issue';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../../api/modules/person';

type RemarkLeftProps = {
  value?: any
  getValue?: () => void //重新请求数据
  joinLab?: () => void   //加入实验室
  gitNo?: () => void    //添加权限
  ifJoin?: any
}


const RemarkLeft: React.FC<RemarkLeftProps> = (props) => {
  const { userInfo } = useSelector((state: any) => state.loginUser)
  const { value, ifJoin = true, getValue, joinLab, gitNo } = props
  const labcode = url.getLabCodeFromHost()
  const [search] = useSearchParams()

  const newLabcode = search.get('labCode') || ''

  //issue ID
  let id = search.get('id') || ''

  // 路由导航
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)                  // 分页器当前页数
  const [pageSize, setPageSize] = useState(10)                       // 分页器每一页的条数
  const [pageTotal, setPageTotal] = useState(0)

  // 富文本编辑器
  const editor = useRef<any>(null)

  //监听评论框是否有值
  const [ifValue, setIfValue] = useState(false)

  //评论
  const [compentList, setCompentList] = useState([])

  //是否处于评论中
  const [compentIng, setCompentIng] = useState(false)

  const getAnsList = () => {
    getAnswerList(
      {
        code: labcode ?? newLabcode,
        id: id,
        current: currentPage,
        size: pageSize
      }
    ).then(res => {
      setCompentList(res.rows)
      setPageTotal(res.total)
    })
  }

  const remark = () => {
    if (compentIng) return
    setCompentIng(true)
    getUserInfo().then(res => {
      if (res.thirdTokens?.length) {
        const msg = res.thirdTokens.find((item: any) => item.type === "github")
        if (msg?.success) {
          const content = editor?.current.getHtml()
          compentAnswers({
            code: labcode ?? newLabcode,
            id: id,
            data: content
          }).then(() => {
            getAnsList()
            message.success('评论成功！')
            setIfValue(false)
            editor?.current.setValue()
            getValue?.()
            setCompentIng(false)
          }).catch(err => {
            message.destroy()
            message.warning(err?.message)
            setCompentIng(false)
          })
        } else {
          setCompentIng(false)
          gitNo?.()
        }
      } else {
        setCompentIng(false)
        gitNo?.()
      }
      // res?.thirdUsers?.forEach((item: any) => {
      //   if (item.success) {
      //     const content = editor?.current.getHtml()
      //     compentAnswers({
      //       code: labcode ?? newLabcode,
      //       id: id,
      //       data: content
      //     }).then(() => {
      //       getAnsList()
      //       message.success('评论成功！')
      //       setIfValue(false)
      //       editor?.current.setValue()
      //       getValue?.()
      //       setCompentIng(false)
      //     }).catch(err => {
      //       message.destroy()
      //       message.warning(err?.message)
      //       setCompentIng(false)
      //     })
      //   } else {
      //     setCompentIng(false)
      //     gitNo?.()
      //   }
      // })

    })
  }

  const pageChage = (currentPage: number, pageSize: number) => {
    setCurrentPage(currentPage)
    setPageSize(pageSize)
  }

  useEffect(() => {
    getAnsList()
  }, [pageSize, currentPage])


  return (
    <div className='remark-left'>
      <div className='remark-title'>
        {value?.title}
      </div>
      <div className='remark-info'>
        <div className='remark-stuta' style={{
          background: value?.statusName === 'open' ? '#1f883d' :
            value?.statusName === 'completed' ? '#8150df' :
              value?.statusName === 'not_planned' ? '#6e7781' : '#ffffff'
        }}>
          {value?.statusCode === 'open' && <img src={require('../../../../assets/images/issue/open.png')} alt="" style={{ width: '12px', height: '12px', marginRight: '4px', marginTop: '2px' }} />}
          {value?.statusCode === 'closed' && <img src={require('../../../../assets/images/issue/close.png')} alt="" style={{ width: '12px', height: '12px', marginRight: '4px', marginTop: '2px' }} />}
          <span>{value?.statusCode}</span>
        </div>
        <div className='remark-message'>
          <div>{value?.createDateTime}</div>
          <div style={{ margin: '0 10px' }}>|</div>
          <div>{value?.answerCount}条评论</div>
        </div>
      </div>
      <div className='questList'>
        <RemarkItem ifQuest={true} value={value}></RemarkItem>
        <div className='questListList'></div>
        {
          compentList.map((item: any) => {
            return <RemarkItem value={item}></RemarkItem>
          })
        }


      </div>
      <div className='remark-Pagination'>
        <div className='remark-Pagination-btn' onClick={() => {
          navigate(-1)
        }}>
          返回列表
        </div>
        <Pagination
          current={currentPage}
          total={pageTotal}
          showQuickJumper={true}
          showSizeChanger={false}
          onChange={pageChage}
          style={{ textAlign: 'right' }}
          pageSize={pageSize}
        />
      </div>
      <div className='remark-comment'>
        <div className='remark-comment-logo'>
          <img className='header-container-content-right-login-user-logo' src={userInfo?.avatar ? userInfo?.avatar : require('../../../../assets/images/signlogo.png')} alt="" />
          <img src={value?.userInfo?.avatar} alt="" style={{ width: '28px', height: '28px', marginRight: '4px', marginTop: '2px' }} />
        </div>
        <div className='remark-comment-content'>
          <div>发表评论</div>
          <div style={{
            marginTop: '8px', border: '0.5px solid #e5e5e5',
            borderRadius: '4px',
            // zIndex: '9999',
            position: 'relative'
          }}>
            <RichEditor ref={editor} editorHeight={120} isShow={true} getHtml={() => {
              const value = editor?.current?.getHtml()
              if (value) {
                setIfValue(true)
              } else {
                setIfValue(false)
              }
            }}></RichEditor>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px' }}>
            <div className='btn-remark' style={{
              background: ifValue && value?.statusCode === 'open' ? '#1677FF' : '#CCCCCC',
              cursor: ifValue && value?.statusCode === 'open' ? 'pointer' : 'not-allowed'
            }} onClick={() => {
              if (value?.statusCode === 'open' && ifValue) {
                if (ifJoin) {
                  remark()
                } else {
                  joinLab?.()
                }
              }
            }}>评论</div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default RemarkLeft;
