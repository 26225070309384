import config from "../../config";
import http from "../index";

// 获取实验室课程
export async function getCourseLab(params: any) {
  return http.get(`/course/lab?labId=${params.labId}`);
}

// 获取课程详情
export async function getCourseInfo(params: any) {
  return http.get(`/course/${params.courseCode}`);
}

// 启动实验环境
export async function startEnvCourse(params: any) {
  return http.post(`/course/env/start`, {
    courseId: params.courseId,
    imageId: params.imageId,
    userId: params.userId
  });
}

// 停止实验环境
export async function stopEnvCourse(params: any) {
  return http.post(`/course/env/stop`, {
    containerId: params.containerId,
    userId: params.userId
  });
}

// 获取重定向地址
export async function getTouchUrl(params: any) {
  return http.get(`/config/touch/url?target=${params.target}`);
}

// 删除实验室
export async function deleteLCourse(params: any) {
  return http.delete(`course/${params.code}`);
}

// 学习技能树获取课程
export async function getLabTree(params: any) {
  return http.post(`/course/recommend`, params)
}

// 获取实验室详情
export async function getLabDetail(params?: any) {
  return http.get(`/lab/${params.code}`)
}

// 获取实验室课程类型
export async function getCourseType(params?: any) {
  return http.get('/config/category', params)
}

// 获取实验室课程  偏移量
export async function getLabCourse(params?: any) {
  return http.post(`/course/recommend`, params)
}



// 获取课程详情  有目录  会比较慢
export async function getDetailCourse(params?: any) {
  return http.get("/course/detail/" + params.code)
}
// 获取课程详情  比较快
export async function getDetailQuickCourse(code: string) {
  return http.get(`/course/${code}`)
}

// 根据envId和实验室code获取课程详情
export async function listByEnvId(params: any) {
  return http.get("/course/listByEnvId?labCode=" + params.labCode + "&envId=" + params.envId)
}

// 获取课程信息
export async function getInfoCourse(params?: any) {
  return http.get("/course/" + params.code)
}

// 访问工作台
export async function getWorkspace(params?: any) {
  return http.get("/course/apply", params)
}

//受邀请者访问工作台
export async function getWorkInvite(params?: any) {
  return http.post("course/study/invite/apply", params)
}

export async function getStudyWorkspace(params?: any) {
  return http.get("/course/study/apply", params)
}

// 获取课程镜像
export async function getImages() {
  // return http.get("/course/images",)
  return http.get(config.openURL + "/repo/images",)
}

// 获取课程状态
export async function getCourseStatus(params: any) {
  return http.get(`course/status?code=${params.code}`,)
}

export async function getStudyCourseStatus(params: any) {
  return http.get(`course/study/status?code=${params.code}`,)
}

// 克隆课程
export async function courseClone(params: any) {
  return http.get(`course/clone?code=${params.code}`,)
}

// 判断是否继续开启工作台
export async function existOpen(params: any) {
  return http.get(`course/stats/exist`, params)
}

// 判断是否继续开启工作台
export async function getCourseList(params: any) {
  return http.post(`course/list`, params)
}

// 查询博客
export async function getBlog(labCode: string) {
  return http.post(`/lab/blog/list?labCode=${labCode}`)
}

// 查询 联系我们 列表
export async function getContactWay(params: any) {
  return http.post(`/labBusiness/contactWay/queryList`, params)
}

// 查询 运营阵地 列表 
export async function getLinkProducts(params: any) {
  return http.post(`/labBusiness/linkProducts/queryList`, params)
}

// 查询 内容专区
export async function getContentZone(params: any) {
  return http.post(`/labBusiness/contentZone/queryList`, params)
}

// 查询 课程目录详情+进度
export async function getCourseDetailAndPro(data: any) {
  return http.get(`/course/detail/progress/${data.code}`)
}

// 学习测同步用户课程统计
export async function syncUser(data: any) {
  return http.post(`/statistics/course/user/single/sync`, {}, { params: data })
}
// 学习测查询同步用户课程统计状态 true-正在同步 false-未同步
export async function syncUserStatus(data: any) {
  return http.get(`/statistics/course/user/single/sync/status`, data)
}