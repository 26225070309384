import React, { useState, useEffect } from 'react'
import "./index.less"
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, ConfigProvider, message, Modal, Tooltip } from 'antd';
import config from '../../../../config';
import Cookies from 'js-cookie';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import postRobot from 'post-robot'
import { saveStudyQuestion } from '../../../../api/modules/exam';
import ExamWarn from '../../../../components/ExamWarn';

interface WorkHeardProps {
  examRecordCode: any       // 考试记录编码
  value: any
  examDetail?: any          // 考试详情
}

const WorkHeard: React.FC<WorkHeardProps> = (props) => {
  const { examRecordCode, value, examDetail } = props

  const [params] = useSearchParams();
  //题目类型 add：添加考试的试卷题目； mark：评分模式； view：查看模式； exam：考试中； preview：预览
  const status = params.getAll("status")[0]

  const navigate = useNavigate()
  // 定时器：30秒发送一次
  const [timer, setTimer] = useState<any>()

  const location = useLocation();

  const [websocket, setWebsocket] = useState<any>()

  //问题code
  let questionCode = params.getAll("questionCode")[0]
  // 是否直接关闭当前页面
  let closeSign = params.getAll("close")[0]

  // 需要重连
  const [reconnect, setReconnect] = useState(false)


  //无效考试
  const [noShow, setNoShow] = useState(false)

  //ws是否连接成功
  const [connect, setConnect] = useState(false)

  //剩余时间
  const [examTime, setExamTime] = useState('')

  const [outTime, setOutTime] = useState(5)

  // 退出倒计时
  const [timer1, setTimer1] = useState<any>()

  const handleWS = () => {
    try {
      if (websocket) {
        websocket?.close()
      }
      const ws = new WebSocket(`${config.wsURL}/api/exam?code=${examRecordCode}&token=${Cookies.get('token') ?? ''}`);
      setWebsocket(ws)
      setReconnect(false)
      timer && clearInterval(timer)

      ws.onopen = function () {
        setReconnect(false)
        setConnect(true)
      }

      ws.onmessage = function (res: any) {
        const { data } = res
        try {
          const _data = JSON.parse(data)
          if (_data.expire === '00:00:-2') {
            setExamTime('00:00:00')
            let time = 5
            setNoShow(true)
            if (value?.optionJson?.defaultLanguage === 'HTML+CSS+Javascript') {
              saveAndSubmit()
            }
            const _timer1 = setInterval(() => {
              time = time - 1
              setOutTime(time)
            }, 1000)
            setTimer1(_timer1)
          } else {
            setExamTime(_data.expire)
          }
        } catch (error) {
        }
      }

      ws.onclose = function (res: any) {

        setConnect(false)
        timer && clearInterval(timer)
        if (res?.code !== 1000 && res?.code < 3000) {
          setReconnect(true)
        } else {
          setNoShow(true)
        }

      }
    } catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    if (connect) {
      const _timer = setInterval(() => {
        if (websocket) {
          websocket?.send('ping')
        }
      }, 1000)
      setTimer(_timer)
    } else {
      timer && clearInterval(timer)
    }
  }, [connect])

  useEffect(() => {
    return () => {
      timer && clearInterval(timer)
    }
  }, [timer])

  useEffect(() => {
    if (outTime === 0) {
      timer1 && clearInterval(timer1)
      setNoShow(false)
      // apExam()
      window.close()
      if (examDetail?.examType === 'POINT') {
        navigate(`/exam-success?examCode=${examDetail?.examCode}`)
      }
    }
  }, [outTime, examDetail?.examType])


  // 间隔1s重连
  useEffect(() => {
    if (reconnect) {
      const _timer = setTimeout(() => {
        clearTimeout(_timer)
        handleWS()
      }, 1000);
    }
  }, [reconnect])

  // 状态：考试中
  useEffect(() => {
    if (examRecordCode && status === 'exam') {
      handleWS()
    }
  }, [])

  const outExam = () => {
    if (status === 'exam') { 
      Modal.confirm({
        title: '确认退出？',
        icon: <ExclamationCircleFilled />,
        content: value?.optionJson?.defaultLanguage === 'HTML+CSS+Javascript' ? '已为你保存代码，请确认代码后再退出' : '已为你实时保存代码，请确认提交后再退出。',
        okText: '确定',
        centered: true,
        cancelText: '取消',
        async onOk() {
          if (value?.optionJson?.defaultLanguage === 'HTML+CSS+Javascript') {
            await saveAndSubmit()
          }
          navigate(location.state?.from ?? "/", {
            state: {
              from: location,
              scrollTop: location?.state?.scrollTop,
            }
          })
        },
        onCancel() {

        },
      })
    } else {
      if (closeSign === '1') {
        window.close()
        return
      }
      navigate(location.state?.from ?? "/", {
        state: {
          from: location,
          scrollTop: location?.state?.scrollTop,
        }
      })
    }
  }

  const [loading, setLoading] = useState(false)
  // const run = async () => {
  //   setLoading(true)
  //   const res = await postRobot.send(window, 'run', {save: true}).catch(() => {}).finally(() => {
  //     setLoading(false)
  //   })
  // }

  const saveAndSubmit = async () => {
    const data: any = await postRobot.send(window, 'save').catch(() => {})
    //保存考试
    const res = await saveStudyQuestion({
      answer: data?.data?.oid,
      examRecordCode: examRecordCode,
      questionCode: questionCode,
      language: value?.optionJson?.defaultLanguage
    }).catch(res => {
      message.destroy()
      message.error(res?.message)
    })
  }

  return (
    <div className='work-heard'>
      <div className='work-heard-left'>
        <span className='work-heard-left-text1'>【考试】</span>
        <Tooltip placement='bottom' title={value?.topic} color='#fff' arrow={false} overlayClassName='platfrom-tooltip-1'>
          <span className='work-heard-left-text2'>{value?.topic}</span>
        </Tooltip>
      </div>
      <div className='work-heard-rigth'>
        {/* {
          value?.optionJson?.defaultLanguage === 'HTML+CSS+Javascript' && <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: `#30C213`,
                  colorPrimaryHover: `#59CE42`,
                  colorPrimaryActive: `#59CE42`,
                  lineWidth: 0,
                },
              },
            }}
          >
            <Button type='primary' loading={loading} className='work-heard-rigth-run' onClick={run}>运行</Button>
          </ConfigProvider>
        } */}
        <div className='rigth-text'>{examTime}</div>
        <Tooltip placement='bottom' title="退出" color='#fff' arrow={false} overlayClassName='platfrom-tooltip-1'>
          <div className='rigth-btn' onClick={outExam}>
            <img src={require('../../../../assets/images/workClose.png')} style={{ width: '16px' }} alt="" />
          </div>
        </Tooltip>
      </div>
      <Modal wrapClassName={'timeOver'} open={noShow} footer={''} width={'414px'} style={{ top: "40%" }}>
        <div>
          <div className='timeOver-top'>
            <ExclamationCircleFilled style={{ color: '#FA8C16', fontSize: '22px' }} />
            <span className='timeOver-text'>考试时间到！</span>
          </div>
          <div className='timeOver-text1'>考试时间已到，已为你自动提交试卷。</div>
          <div className='timeOver-bottom'>
            <div className='timeOver-btn' style={{
              width: '123px'
            }} onClick={() => {
              window.close()
              if (examDetail?.examType === 'POINT') {
                navigate(`/exam-success?examCode=${examDetail?.examCode}`)
              }
            }}>{`我知道了（${outTime}s)`}</div>
          </div>
        </div>
      </Modal>

      { connect && <ExamWarn />}
    </div>
  )
}

export default WorkHeard;
